import React, { useState } from 'react';
import { Week, WeekComparator } from '../@types/compteur';
const HistoryPage = (props: { weeks: Week[] }) => {
    const [weeks, setWeeks] = useState<Week[]>(props.weeks);

    const columns: TableColumn[] = [
        {
            label: 'Date',
            getter: (week: Week) => week.date.toLocaleDateString('fr-BE'),
            comparator: (w1: Week, w2: Week) => w1.date.toString().localeCompare(w2.date.toString())
        },
        {
            label: 'Semaine',
            getter: (week: Week) => week.days.reduce((p, c) => p + ' ' + c, ''),
            comparator: (w1: Week, w2: Week) => w1.days.reduce((p, c) => p + c, 0) - w2.days.reduce((p, c) => p + c, 0)
        },
        {
            label: 'Total',
            getter: (week: Week) => week.days.reduce((p, c) => p + c, 0).toString(),
            comparator: (w1: Week, w2: Week) => w1.days.reduce((p, c) => p + c, 0) - w2.days.reduce((p, c) => p + c, 0)
        }
    ];

    const handleSorting = (comparator: WeekComparator, sortOrder: SortOrder) => {
        const isAsc = sortOrder === 'asc' ? 1 : -1;
        const sortedWeek = weeks.slice().sort((w1, w2) => {
            const compResult = comparator(w1, w2);
            return compResult * isAsc;
        });

        setWeeks(sortedWeek);
    };

    return (
        <div className="container">
            <h1 className="text-center mb-4">Historique</h1>
            <table className="table table-striped table-hover">
                <TableHead {...{ columns, handleSorting }} />
                <TableBody {...{ columns, weeks }} />
            </table>
        </div>
    );
};

type TableColumn = {
    label: string;
    getter: (week: Week) => string;
    comparator: WeekComparator;
};

type TableHeadProps = {
    columns: TableColumn[];
    handleSorting: (comparator: WeekComparator, sortOrder: SortOrder) => void;
};

type SortOrder = 'asc' | 'desc';

const TableHead = (props: TableHeadProps) => {
    const { columns, handleSorting } = props;

    const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

    const handlingSortingChange = (column: TableColumn) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        handleSorting(column.comparator, newSortOrder);
    };

    return (
        <thead>
            <tr>
                {columns.map(column => (
                    <th role="button" style={{}} key={column.label} onClick={() => handlingSortingChange(column)}>
                        {column.label}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

type TableBodyProps = {
    columns: TableColumn[];
    weeks: Week[];
};

const TableBody = (props: TableBodyProps) => {
    const { weeks, columns } = props;

    return (
        <tbody>
            {weeks.map(week => (
                <tr key={week.date.toString()}>
                    {columns.map(({ getter, label }) => (
                        <td key={label}>{getter(week)}</td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
};

export default HistoryPage;
