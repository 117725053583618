import React, { FormEvent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContextType } from '../@types/compteur';
import { useAuthContext } from '../contexts/AuthContext';

const ForgotPassword = () => {
    const [error, setError] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const emailRef = useRef<HTMLInputElement>(null);
    const { resetPassword } = useAuthContext() as AuthContextType;

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            if (emailRef.current && emailRef.current.value !== null) {
                await resetPassword(emailRef.current.value);
            }
            setMessage('Un lien de réinitialisation vous a été envoyé par e-mail');
        } catch {
            setError("Le mot de passe n'a pas pu être réinitialisé");
            setLoading(false);
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
            <div style={{ maxWidth: '500px', width: '100%' }}>
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4">Mot de passe oublié &#128531; ?</h2>
                        {error && <div className="alert alert-danger">{message}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group ">
                                <label className="form-label">Email</label>
                                <input className="form-control" required ref={emailRef} type="email" />
                            </div>
                            <div className="w-100 d-flex justify-content-center">
                                <button className="btn btn-primary mt-4 w-80 mb-4" type="submit" disabled={loading}>
                                    Réinitialiser mon mot de passe
                                </button>
                            </div>
                        </form>
                        <div className="text-center w-100">
                            <Link to="/authentification">S'authentifier</Link>
                        </div>
                    </div>
                </div>
                <div className="w-100 text-center mt-2">
                    <Link to="/engistrement">Besoin d'un compte ?</Link>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
