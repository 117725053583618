import React, { FormEvent, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContextType } from '../@types/compteur';
import { useAuthContext } from '../contexts/AuthContext';
import { getNulls } from '../utils';

const Signup = () => {
    const passwordRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const confirmationRef = useRef<HTMLInputElement>(null);

    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const { signup } = useAuthContext() as AuthContextType;

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (passwordRef.current!.value !== confirmationRef.current!.value) {
            setError('Les mots de passe ne sont pas les mêmes');
            return;
        }

        if (getNulls(emailRef.current, passwordRef.current, confirmationRef.current).length > 0) {
            setError(`Les champs ne sont pas tous completés`);
            return;
        }

        try {
            setError('');
            setLoading(true);
            await signup(emailRef.current!.value, passwordRef.current!.value);
            history.push('/app');
        } catch {
            setError("Le compte n'a pas pu être créé");
        }
        setLoading(false);
    };

    return (
        <div className="container d-flex align-items-center justify-content-center " style={{ minHeight: '80vh' }}>
            <div style={{ width: '100%', maxWidth: '400px' }}>
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4">S'enregister</h2>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-2">
                                <label className="form-label">Email</label>
                                <input className="form-control" type="email" placeholder="Adresse Email" ref={emailRef} required />
                            </div>
                            <div className="form-group mb-2">
                                <label className="form-label">Mot de passe</label>
                                <input className="form-control" type="password" placeholder="Mot de passe" ref={passwordRef} required />
                            </div>
                            <div className="form-group mb-2">
                                <label className="form-label">Confirmation</label>
                                <input className="form-control" type="password" ref={confirmationRef} placeholder="Confirmation" required />
                            </div>
                            <button disabled={loading} className="btn btn-primary w-100 mt-2 mb-2" type="submit">
                                S'enregistrer
                            </button>
                        </form>
                    </div>
                </div>
                <div className="text-center w-100 mt-2">
                    <Link to="/authentification">Déjà un compte ?</Link>
                </div>
            </div>
        </div>
    );
};

export default Signup;
