import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    User,
    UserCredential,
    updateEmail,
    updatePassword,
    GoogleAuthProvider,
    signInWithPopup
} from 'firebase/auth';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContextType } from '../@types/compteur';
import { auth } from '../myfirebase';

const AuthContext = React.createContext<AuthContextType | null>(null);
const provider = new GoogleAuthProvider();

export const useAuthContext = () => {
    return useContext(AuthContext);
};

export const AuthProvider = (props: { children: React.ReactNode }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    function signup(email: string, password: string): Promise<UserCredential> {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function login(email: string, password: string): Promise<UserCredential> {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function loginGoogle(): Promise<UserCredential> {
        return signInWithPopup(auth, provider);
    }

    function logout() {
        return signOut(auth);
    }

    function resetPassword(email: string): Promise<void> {
        return sendPasswordResetEmail(auth, email);
    }

    function editEmail(email: string): Promise<void> {
        if (!currentUser) {
            throw Error('No current user defined');
        }
        return updateEmail(currentUser, email);
    }

    function editPassword(password: string): Promise<void> {
        if (!currentUser) {
            throw Error('No current user defined');
        }
        return updatePassword(currentUser, password);
    }

    const value = {
        currentUser,
        login,
        loginGoogle,
        logout,
        signup,
        resetPassword,
        editEmail,
        editPassword
    };

    return <AuthContext.Provider value={value}>{!loading && props.children}</AuthContext.Provider>;
};
