import React from 'react';
import { Week } from '../@types/compteur';

const StatisticPage = (props: { weeks: Week[] }) => {
    const { weeks } = props;

    const sums = weeks.map(w => w.days.reduce((d1, d2) => d1 + d2));
    const total = sums.reduce((s1, s2) => s1 + s2);
    const avg = total / weeks.length;
    const max = Math.max(...sums);
    const min = Math.min(...sums);
    const extraAvg = sums.map((s, i) => s - weeks[i].target).reduce((x1, x2) => x1 + x2) / weeks.length;

    return (
        <div className="container">
            <table className="table">
                <tbody>
                    <tr>
                        <th>Statistiques</th>
                        <th>Valeur</th>
                    </tr>
                    <tr>
                        <td>Moyenne</td>
                        <td>{avg.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Maximum</td>
                        <td>{max}</td>
                    </tr>
                    <tr>
                        <td>Minimum</td>
                        <td>{min}</td>
                    </tr>
                    <tr>
                        <td>Moyenne Extra</td>
                        <td>{extraAvg.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td>{total}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default StatisticPage;
