import React, { FormEvent, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContextType } from '../@types/compteur';
import { useAuthContext } from '../contexts/AuthContext';
import { getNulls } from '../utils';

const Login = () => {
    const passwordRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);

    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    const { login, loginGoogle } = useAuthContext() as AuthContextType;

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (getNulls(emailRef.current, passwordRef.current).length > 0) {
            setError('Les champs ne sont pas tous completés');
            return;
        }

        try {
            setError('');
            setLoading(true);
            await login(emailRef.current!.value, passwordRef.current!.value);
            history.push('/app');
        } catch {
            setError("Une erreur s'est produite durant l'authentification");
        }
        setLoading(false);
    };

    const handleLoginGoogle = async () => {
        setError('');
        setLoading(true);
        await loginGoogle();
        history.push('/app');
    };

    return (
        <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: '80vh' }}>
            <div className="w-100" style={{ maxWidth: '400px' }}>
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4">Se connecter</h2>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-2">
                                <label className="form-label">Adresse email</label>
                                <input className="form-control" ref={emailRef} type="email" placeholder="Adresse email" />
                            </div>
                            <div className="form-group mb-2">
                                <label className="form-label">Mot de passe</label>
                                <input className="form-control" ref={passwordRef} type="password" placeholder="Mot de passe" />
                            </div>
                            <button disabled={loading} className="btn btn-primary w-100 mt-2 mb-2" type="submit">
                                Se connecter
                            </button>
                            <button disabled={loading} className="btn btn-primary w-100 mb-4" type="button" onClick={handleLoginGoogle}>
                                Se connecter avec Google
                            </button>
                        </form>
                        <div className="text-center w-100">
                            <Link to="/motdepasseoublie">Mot de passe oublié ? </Link>
                            <p>&#128531;</p>
                        </div>
                    </div>
                </div>
                <div className="w-100 text-center mt-2">
                    <Link to="/enregistrement">Besoin d'un compte ?</Link>
                    <p>&#128519; </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
