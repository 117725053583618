import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AuthContextType } from '../@types/compteur';
import { useAuthContext } from '../contexts/AuthContext';

const PrivateRoute = (routeProps: RouteProps) => {
    const { currentUser } = useAuthContext() as AuthContextType;
    return currentUser ? <Route {...routeProps} /> : <Redirect to="/authentification" />;
};


export default PrivateRoute;
