import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

type MobileSwipperProps = {
    children: ReactNode;
    onSwipe: (deltaX: number, deltaY: number) => void;
};

export const MobileSwipper = ({ children, onSwipe }: MobileSwipperProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [startX, setStartX] = useState<number>(-1);
    const [startY, setStartY] = useState<number>(-1);

    const handleTouchStart = useCallback((e: TouchEvent) => {
        if (ref && ref.current && ref.current.contains(e.target as Node)) {
            setStartX(e.touches[0].clientX);
            setStartY(e.touches[0].clientY);
        }
    }, []);

    const handleTouchEnd = useCallback( (e: TouchEvent) => {
        if (ref && ref.current && ref.current.contains(e.target as Node)) {
            const deltaX = e.changedTouches[0].clientX - startX;
            const deltaY = e.changedTouches[0].clientY - startY;
            onSwipe(deltaX, deltaY);
        }
    }, [startX, startY, onSwipe])

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handleTouchEnd, handleTouchStart]);

    return (
        <div style={{ touchAction: 'none' }} ref={ref}>
            {children}
        </div>
    );
};
