import { Week } from "./@types/compteur";

export function getDaysName(date: Date) {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric' };

    const daysName = [];
    let workingDate = new Date(date);
    for (let index = 0; index < 7; index++) {
        daysName.push(workingDate.toLocaleDateString('fr-BE', options));
        workingDate = addDays(workingDate, 1);
    }

    return daysName;
}

export function addDays(date: Date, days: number) {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

export function formatToMonth(date: Date) {
    const mondayDayOfMonth = date.getDate();
    const sunday = addDays(date,6);
    const sundayDayOfMonth = addDays(date, 6).getDate();
    let month = sunday.toLocaleDateString('fr-BE', { month: 'long' });

    return `${mondayDayOfMonth} ➔ ${sundayDayOfMonth} ${capitalizeFirstLetter(month)}`;
}

function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getCurrentMonday() : Date {
    let today = new Date();
    const dayOfWeek = today.getDay();
    const newDayOfMonth = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
    return new Date(today.setDate(newDayOfMonth));
}

export function getNewWeek(date: Date): Week {
    return {
        date,
        days: new Array(7).fill(0),
        target: 20,
        completed: false
    };
}

export function getIsoDate(date: Date){
    return date.toISOString().substring(0, 10);
}