import { addDays, formatToMonth, getDaysName, getIsoDate, getNewWeek } from '../date-utils';
import { AuthContextType, Week, WeekNavigatorProps, WeekPageProps, WeekTableProps, WeekValidatorProps } from '../@types/compteur';

import React, { useState } from 'react';
import { setDoc, doc } from 'firebase/firestore';
import { firestoreService } from '../myfirebase';
import { useAuthContext } from '../contexts/AuthContext';
import { upperCaseFirstLetter } from '../utils';
import { useMediaQuery } from 'react-responsive';
import { MobileSwipper } from './MobileSwipper';

const WeekPage = (props: WeekPageProps) => {
    const { index, weeks, onWeeksChange, onIndexChange } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const { currentUser } = useAuthContext() as AuthContextType;

    const extra: number = weeks
        .slice()
        .filter(w => w.completed)
        .map(w => w.target - w.days.reduce((p, c) => p + c, 0))
        .reduce((p, c) => p + c, 0);

    const currentWeek = weeks[index];

    const handleIndexChange = (count: number) => {
        const currentIndex = index;
        const length = weeks.length;

        if (count < 0 && currentIndex === 0) {
            const newWeek = getNewWeek(addDays(weeks[currentIndex].date, -7));
            const newWeeks: Week[] = [newWeek].concat(weeks);
            onIndexChange(0);
            onWeeksChange(newWeeks);
        } else if (count > 0 && currentIndex === length - 1) {
            const newWeek = getNewWeek(addDays(weeks[currentIndex].date, 7));
            const newWeeks: Week[] = weeks.concat([newWeek]);
            onIndexChange(weeks.length);
            onWeeksChange(newWeeks);
        } else {
            const newIndex = index + count;
            onIndexChange(newIndex);
        }
    };

    const handleCurrentWeekChange = (week: Week) => {
        const newWeeks = weeks.slice();
        newWeeks[index] = week;
        onWeeksChange(newWeeks);
    };

    const saveWeek = async (week: Week) => {
        if (currentUser) {
            const docRef = doc(firestoreService, 'users', currentUser.uid, 'weeks', getIsoDate(week.date));
            await setDoc(docRef, week);
        }
    };

    const toggleValidate = () => {
        const wasCompleted = weeks[index].completed;

        const newWeeks = weeks.slice();
        newWeeks[index].completed = !newWeeks[index].completed;

        if (wasCompleted) {
            onWeeksChange(newWeeks);
        } else {
            setLoading(true);
            saveWeek(weeks[index]).then(() => {
                onWeeksChange(newWeeks);
                setLoading(false);
            });
        }
    };

    const notMobile = useMediaQuery({
        query: '(min-width: 576px)'
    });

    const onSwipe = (deltaX: number, deltaY: number) => {
        if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 100) {
            if (deltaX < 0) {
                handleIndexChange(1);
            } else {
                handleIndexChange(-1);
            }
        }
    };

    return (
        <div className="container">
            <WeekSummary week={currentWeek} extra={extra} />
            <MobileSwipper onSwipe={(deltaX, deltaY) => onSwipe(deltaX, deltaY)}>
                <WeekTable onChange={handleCurrentWeekChange} week={currentWeek} loading={loading} />
            </MobileSwipper>
            {notMobile && <WeekNavigator onIndexChange={handleIndexChange} loading={loading} />}
            <WeekValidator onToggle={toggleValidate} completed={currentWeek.completed} loading={loading} />
        </div>
    );
};

const WeekSummary = (props: { week: Week; extra: number }) => {
    const { week, extra } = props;

    const done = week.days.reduce((a, b) => a + b, 0);
    const leftTodo = week.target - done;

    return (
        <div className="d-flex flex-column align-items-center">
            <h1 className="h1 mb-4">{formatToMonth(week.date)}</h1>
            <div className="d-flex w-100 justify-content-around mb-4" style={{ maxWidth: '800px' }}>
                <div>Extra {extra}</div>
                <div>À faire {week.target}</div>
                <div>Faite(s) {done}</div>
                <div>Restante(s) {leftTodo}</div>
            </div>
        </div>
    );
};

const WeekTable = (props: WeekTableProps) => {
    const { week, onChange: onWeekChange, loading } = props;

    const daysName = getDaysName(week.date);

    const days = week.days.map((d, i) => (
        <div key={daysName[i]} className="form-group text-center m-2">
            <label className="form-label">{upperCaseFirstLetter(daysName[i])}</label>
            <input
                style={{ height: '40px', fontSize: '1.2em', touchAction:'none' }}
                className="form-control text-center"
                onChange={e => {
                    const newWeek = Object.assign({}, week);
                    const newValue = parseFloat(e.target.value);
                    newWeek.days[i] = newValue ? newValue : 0;
                    onWeekChange(newWeek);
                }}
                value={d}
                type="number"
                name="time"
                pattern="[0-9]{1,2}([\.,][0-9]{0,2})?"
                step="0.5"
                title="Entrez un nombre d'heures"
                min={0}
                max={24}
                onFocus={e => e.target.select()}
                disabled={week.completed || loading}></input>
        </div>
    ));

    return <div className="d-flex justify-content-center flex-wrap mb-4">{days}</div>;
};

const WeekNavigator = (props: WeekNavigatorProps) => {
    const { onIndexChange: onChange } = props;

    return (
        <div className="d-flex justify-content-around">
            <button className="btn btn-primary btn-lg" onClick={() => onChange(-1)}>
                &lt;
            </button>
            <button className="btn btn-primary btn-lg" onClick={() => onChange(1)}>
                &gt;
            </button>
        </div>
    );
};

const WeekValidator = (props: WeekValidatorProps) => {
    const { completed, loading, onToggle } = props;

    return (
        <div className="d-flex justify-content-center mb-4">
            {loading ? (
                'Sauvegarde en cours...'
            ) : (
                <button className="btn btn-primary" onClick={onToggle}>
                    {completed ? `Modifier à nouveau` : `Valider la semaine`}
                </button>
            )}
        </div>
    );
};

export default WeekPage;
