import { getCurrentMonday, getNewWeek } from '../date-utils';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { firestoreService } from '../myfirebase';
import Signup from './Signup';
import Login from './Login';
import { AppProps, AuthContextType, Week } from '../@types/compteur';
import { AuthProvider, useAuthContext } from '../contexts/AuthContext';
import { BrowserRouter, Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import WeekPage from './WeekPage';
import { AccountPage } from './AccountPage';
import HistoryPage from './HistoryPage';
import StatisticPage from './StatisticPage';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './ForgotPassword';

const App = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Switch>
                    <Route path="/enregistrement" component={Signup} />
                    <Route path="/authentification" component={Login} />
                    <Route path="/motdepasseoublie" component={ForgotPassword} />
                    <PrivateRoute path="/app" component={AppStateContainer} />
                    <Route>
                        <Redirect to="/app" />
                    </Route>
                </Switch>
            </AuthProvider>
        </BrowserRouter>
    );
};

const AppStateContainer = () => {
    const [weeks, setWeeks] = useState<Week[] | null>(null);
    const [index, setIndex] = useState<number | null>(null);
    const { currentUser } = useAuthContext() as AuthContextType;

    useEffect(() => {
        if (currentUser) {
            fetchWeeks(currentUser.uid).then(weeks => {
                setWeeks(weeks);
                setIndex(weeks.length - 1);
            });
        }
    }, [currentUser]);

    const fetchWeeks = async (uid: string) => {
        const newWeeks: Week[] = [];
        const collectionRef = collection(firestoreService, 'users', uid, 'weeks');
        const weekQuery = query(collectionRef, orderBy('date'));
        const querySnapshot = await getDocs(weekQuery);

        if (querySnapshot.empty) {
            newWeeks.push(getNewWeek(getCurrentMonday()));
        } else {
            querySnapshot.forEach(doc => {
                let week = doc.data();
                week.date = week.date.toDate();
                newWeeks.push(week as Week);
            });
        }

        return newWeeks;
    };

    return weeks !== null && index !== null ? (
        <AppRoutes weeks={weeks} index={index} onIndexChange={index => setIndex(index)} onWeeksChange={weeks => setWeeks(weeks)} />
    ) : (
        <div>Loading ...</div>
    );
};

const AppRoutes = (props: AppProps) => {
    let { path } = useRouteMatch();

    return (
        <>
            <TopNavbar />
            <Switch>
                <Route exact path={`${path}`} render={() => <WeekPage {...props} />}></Route>
                <Route path={`${path}/historique`} render={() => <HistoryPage {...props} />}></Route>
                <Route path={`${path}/statistiques`} render={() => <StatisticPage {...props} />}></Route>
                <Route path={`${path}/moncompte`} component={AccountPage}></Route>
            </Switch>
        </>
    );
};

const TopNavbar = () => {
    return (
        <nav className="mb-4 d-flex justify-content-around">
            <Link className="m-2" to="/app">
                Semaine
            </Link>
            <Link className="m-2" to={`/app/historique`}>
                Historique
            </Link>
            <Link className="m-2" to={`/app/statistiques`}>
                Statistiques
            </Link>
            <Link className="m-2" to={`/app/moncompte`}>
                Compte
            </Link>
        </nav>
    );
};

export default App;
