import React, { FormEvent, useRef, useState } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { AuthContextType } from '../@types/compteur';
import { useHistory } from 'react-router';

export const AccountPage = () => {
    const [error, setError] = useState<string>('');
    const { currentUser, logout, editEmail, editPassword } = useAuthContext() as AuthContextType;
    const history = useHistory();

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleLogout = async () => {
        try {
            setError('');
            await logout();
            history.push('/authentification');
        } catch {
            setError('Une erreur est survenue lors de la déconnection');
        }
    };

    const handleEditProfile = (e: FormEvent) => {
        e.preventDefault();

        setLoading(true);
        setError('');

        if (currentUser === null || currentUser.email === null) {
            throw Error('no user defined');
        }

        const promises = [];

        if (emailRef.current && emailRef.current.value !== currentUser.email) {
            promises.push(editEmail(emailRef.current.value));
        }

        if (passwordRef.current && passwordRef.current.value) {
            promises.push(editPassword(passwordRef.current.value));
        }

        Promise.all(promises)
            .then(() => {
                history.push('/app');
            })
            .catch(() => {
                setError("Le compte n'a pas pu être mis à jour");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="container">
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <div className="mb-2">Utilisateur connecté: {currentUser ? currentUser.email : 'no user'}</div>
                    <div>
                        <button className="btn btn-primary" onClick={handleLogout}>
                            Déconnexion
                        </button>
                        {error && <label color="red" children={error} />}
                    </div>
                </div>

                {currentUser && currentUser.providerData[0].providerId === 'password' && (
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            {error && <label color="red" children={error} />}
                            <form onSubmit={handleEditProfile}>
                                <div className="form-group mb-2">
                                    <label className="form-label">Email </label>
                                    <input
                                        className="form-control"
                                        required
                                        ref={emailRef}
                                        type="email"
                                        defaultValue={currentUser && currentUser.email ? currentUser.email : ''}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label className="form-label">Mot de passe </label>
                                    <input
                                        className="form-control"
                                        ref={passwordRef}
                                        type="password"
                                        placeholder="conserver mot de passe"
                                    />
                                </div>
                                <button className="btn btn-primary" type="submit" form="registerForm" disabled={loading}>
                                    Changer mon email et/ou mon mot de passe
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
